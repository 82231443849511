

input{
    padding: 12px 20px;
    border: none;
    background: #f7f6f6;
    border-radius: 5px;
    position: relative;
    width: 100%;
}

