.cardContainer {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10rem;
    margin-top: 2rem;
    z-index: -2;
    justify-content: center;
  }
  
  @media screen and (max-width: 768px) {
    .cardContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
  }
  
  .card {
    margin: 20px;
    border: 2px solid #ededed;
    padding: 20px;
    cursor: pointer;
    box-sizing: border-box;
  }
  
  .cardImg {
    width: 100%;
    height: auto;
    max-height: 200px; /* Set a max height for the images */
    object-fit: contain; /* Center and contain images within the container */
    margin: 0 auto;
  }
  
  .cardTitle {
    margin-bottom: 0.5rem;
  }
  