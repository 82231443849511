
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

a{
    text-decoration: none;
    color: rgb(97,97,97);
}

li{
    list-style: none;

}

