.card {
  width: 25rem;
  margin: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: transform 0.3s ease;
  justify-content: center;
}

.card:hover {
  transform: scale(1.05); /* Scale up the card on hover */
}

.cardImg {
  width: 100vw;
  height: 100vh;
}

.cardDetails {
  padding: 2px;
}

.cardTitle {
  font-size: 16px;
  margin-bottom: 5px;
}

.cardSize {
  font-size: 14px;
  color: #666;
}

.cardColor {
  font-size: 14px;
  color: #333;
}

.cardCategory {
  font-size: 14px;
  color: #333;
}

.cardDim {
  font-size: 14px;
  color: #333;
}